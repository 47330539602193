import { useState, useCallback, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { get, set } from "lodash";
import "./App.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const defaultValue = [
  100, 105, 115.5, 132.83, 159.39, 105, 110.25, 115.76, 121.55, 127.63, 110,
  115.5, 121.28, 127.34, 133.71, 115, 120.75, 126.79, 133.13, 139.78, 120, 126,
  132.3, 138.92, 145.86,
];

const tempValue = {
  level1: {
    tier1: 100,
    tier2: 105,
    tier3: 115.5,
    tier4: 132.83,
    tier5: 159.39,
  },
  level2: {
    tier1: 105,
    tier2: 110.25,
    tier3: 115.76,
    tier4: 121.55,
    tier5: 127.63,
  },
  level3: {
    tier1: 110,
    tier2: 115.5,
    tier3: 121.28,
    tier4: 127.34,
    tier5: 133.71,
  },
  level4: {
    tier1: 115,
    tier2: 120.75,
    tier3: 126.79,
    tier4: 133.13,
    tier5: 139.78,
  },
  level5: {
    tier1: 120,
    tier2: 126,
    tier3: 132.3,
    tier4: 138.92,
    tier5: 145.86,
  },
};

function Building({
  typeName,
  keyName,
  defaultValue,
  updateValue,
  changeValue,
}) {
  const xRow = [0, 1, 2, 3, 4];
  const yRow = [0, 1, 2, 3, 4];
  return (
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{typeName}</TableCell>
              <TableCell align="right">Tier 1</TableCell>
              <TableCell align="right">Tier 2</TableCell>
              <TableCell align="right">Tier 3</TableCell>
              <TableCell align="right">Tier 4</TableCell>
              <TableCell align="right">Tier 5</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {yRow.map((yValue) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{yValue + 1}</TableCell>
                {xRow.map((xValue) => (
                  <TableCell align="right">
                    <TextField
                      type="number"
                      value={get(
                        updateValue,
                        `${keyName}.level${yValue + 1}.tier${xValue + 1}`,
                        defaultValue[yValue * 5 + xValue]
                      )}
                      onChange={(e) =>
                        changeValue(
                          `${keyName}.level${yValue + 1}.tier${xValue + 1}`,
                          e.target.value
                        )
                      }
                      variant="standard"
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function App() {
  const [result, setResult] = useState({
    goldProduce: 0,
    goldStorage: 0,
    goldLimited: 0,
    materialProduce: 0,
    materialStorage: 0,
    materialLimited: 0,
    defense: 0,
    population: 0,
  });
  const [time, setTime] = useState(1);
  const [block, setBlock] = useState([
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "apartment",
    },
    {
      tier: 1,
      level: 1,
      type: "mining",
    },
    {
      tier: 1,
      level: 1,
      type: "mining",
    },
    {
      tier: 1,
      level: 1,
      type: "mining",
    },
    {
      tier: 1,
      level: 1,
      type: "mining",
    },
    {
      tier: 1,
      level: 1,
      type: "mining",
    },
    {
      tier: 1,
      level: 1,
      type: "factory",
    },
    {
      tier: 1,
      level: 1,
      type: "factory",
    },
    {
      tier: 1,
      level: 1,
      type: "factory",
    },
    {
      tier: 1,
      level: 1,
      type: "gold storage",
    },
    {
      tier: 1,
      level: 1,
      type: "material storage",
    },
  ]);
  const [landTier, setLandTier] = useState(1);
  const [numOfRoom, setNumOfRoom] = useState(1);
  const [fiveDifferentRoom, setFiveDifferentRoom] = useState(false);
  const [landValue, setLandValue] = useState({
    goldStorage: {
      tier1: 100,
      tier2: 200,
      tier3: 300,
      tier4: 400,
      tier5: 500,
    },
    goldRate: {
      tier1: 1.01,
      tier2: 1.02,
      tier3: 1.03,
      tier4: 1.04,
      tier5: 1.05,
    },
    materialStorage: {
      tier1: 100,
      tier2: 200,
      tier3: 300,
      tier4: 400,
      tier5: 500,
    },
    materialRate: {
      tier1: 1.01,
      tier2: 1.02,
      tier3: 1.03,
      tier4: 1.04,
      tier5: 1.05,
    },
    denfense: {
      tier1: 100,
      tier2: 200,
      tier3: 300,
      tier4: 400,
      tier5: 500,
    },
    offense: {
      tier1: 100,
      tier2: 200,
      tier3: 300,
      tier4: 400,
      tier5: 500,
    },
  });
  const [roomValue, setRoomValue] = useState({
    room1: 1.01,
    room2: 1.02,
    room3: 1.03,
    room4: 1.04,
    room5: 1.05,
    roomExtra: 1.05,
  });
  const [buildingValue, setBuildingValue] = useState({
    factoryMaterialProduce: tempValue,
    buildingMaterialStorage: tempValue,
    apartmentPopulation: tempValue,
    apartmentDefense: tempValue,
    goldMine: tempValue,
    goldStorage: tempValue,
  });

  useEffect(() => {
    let tempGoldProduce = 0;
    let tempGoldStorage = 0;
    let tempGoldLimited = 0;
    let tempMaterialProduce = 0;
    let tempMaterialStorage = 0;
    let tempMaterialLimited = 0;
    let tempDefense = 0;
    let tempPopulation = 0;
    // Land
    const landGoldRate = get(landValue, `goldRate.tier${landTier}`, 1);
    const landGoldStorage = get(landValue, `goldStorage.tier${landTier}`, 100);
    const landMaterialRate = get(landValue, `materialRate.tier${landTier}`, 1);
    const landMaterialStorage = get(landValue, `materialStorage.tier${landTier}`, 100);
    const landDefense = get(landValue, `denfense.tier${landTier}`, 1);
    const landPopulation = get(landValue, `offense.tier${landTier}`, 100);

    block.forEach(item => {
      if (item.type ==='apartment') {
        const buildingDefense = get(buildingValue, `apartmentDefense.level${item.level}.tier${item.tier}`, 0);
        const buildingOffense = get(buildingValue, `apartmentPopulation.level${item.level}.tier${item.tier}`, 0);
        tempDefense += buildingDefense;
        tempPopulation += buildingOffense;
      }
      if (item.type ==='material storage') {
        const buildingMaterialStorage = get(buildingValue, `buildingMaterialStorage.level${item.level}.tier${item.tier}`, 0);
        tempMaterialStorage += buildingMaterialStorage;
      }
      if (item.type ==='factory') {
        const buildingMaterialRate = get(buildingValue, `factoryMaterialProduce.level${item.level}.tier${item.tier}`, 0);
        tempMaterialProduce += buildingMaterialRate;
      }
      if (item.type ==='gold storage') {
        const buildingGoldStorage = get(buildingValue, `goldStorage.level${item.level}.tier${item.tier}`, 0);
        tempGoldStorage += buildingGoldStorage;
      }
      if (item.type ==='mining') {
        const buildingGoldRate = get(buildingValue, `goldMine.level${item.level}.tier${item.tier}`, 0);
        tempGoldProduce += buildingGoldRate;
      }
    });
    const roomMultipler = get(roomValue, `room${numOfRoom}`, 1);
    const fiveDifferentRoomMultipler = fiveDifferentRoom ? get(roomValue, `roomExtra`, '1') : 1;

    tempGoldProduce = tempGoldProduce * time * landGoldRate * roomMultipler * fiveDifferentRoomMultipler;
    tempGoldStorage = (tempGoldStorage + landGoldStorage) * roomMultipler * fiveDifferentRoomMultipler;
    tempGoldLimited = (tempGoldStorage > tempGoldProduce) ? tempGoldProduce : tempGoldStorage;
    tempMaterialProduce = tempMaterialProduce * time * landMaterialRate * roomMultipler * fiveDifferentRoomMultipler;
    tempMaterialStorage = (tempMaterialStorage + landMaterialStorage) * roomMultipler * fiveDifferentRoomMultipler;
    tempMaterialLimited = (tempMaterialStorage > tempMaterialProduce) ? tempMaterialProduce : tempMaterialStorage;
    tempDefense = (tempDefense + landDefense) * roomMultipler * fiveDifferentRoomMultipler;
    tempPopulation = (tempPopulation + landPopulation) * roomMultipler * fiveDifferentRoomMultipler;
    setResult({
      goldProduce: tempGoldProduce,
      goldStorage: tempGoldStorage,
      goldLimited: tempGoldLimited,
      materialProduce: tempMaterialProduce,
      materialStorage: tempMaterialStorage,
      materialLimited: tempMaterialLimited,
      defense: tempDefense,
      population: tempPopulation,
    });

  }, [buildingValue, roomValue, landValue, fiveDifferentRoom, numOfRoom, landTier, block, time]);

  const changeValue = useCallback(
    (key, value) => {
      const setValue = Object.assign({}, landValue);
      set(setValue, key, value);
      setLandValue(setValue);
    },
    [setLandValue, landValue]
  );

  const changeRoomValue = useCallback(
    (key, value) => {
      const setValue = Object.assign({}, roomValue);
      set(setValue, key, value);
      setRoomValue(setValue);
    },
    [setRoomValue, roomValue]
  );

  const changeBuildingValue = useCallback(
    (key, value) => {
      const setValue = Object.assign({}, buildingValue);
      set(setValue, key, value);
      setBuildingValue(setValue);
    },
    [buildingValue, setBuildingValue]
  );

  const changeBlockValue = useCallback(
    (key, value) => {
      const setValue = Object.assign([], block);
      set(setValue, key, value);
      setBlock(setValue);
    },
    [block, setBlock]
  );
  return (
    <div className="App">
      <Container>
        <TableContainer component={Paper}>
          <TableRow>
            <TableCell>Time:</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                variant="standard"
              />
            </TableCell>
            <TableCell>Land Tier:</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                value={landTier}
                onChange={(e) => setLandTier(e.target.value)}
                variant="standard"
              />
            </TableCell>
            <TableCell>Number of Room:</TableCell>
            <TableCell align="right">
              <TextField
                type="number"
                value={numOfRoom}
                onChange={(e) => setNumOfRoom(e.target.value)}
                variant="standard"
              />
            </TableCell>
            <TableCell>Five Different Room:</TableCell>
            <TableCell align="right">
              <FormControlLabel
                control={
                  <Switch
                    value={fiveDifferentRoom}
                    onChange={(e) => setFiveDifferentRoom(e.target.checked)}
                  />
                }
                label="Five Different Rooms"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Gold Produce:</TableCell>
            <TableCell align="right">
              {get(result, 'goldProduce', 0)}
            </TableCell>
            <TableCell>Gold Storage:</TableCell>
            <TableCell align="right">
            {get(result, 'goldStorage', 0)}
            </TableCell>
            <TableCell>Gold Limited:</TableCell>
            <TableCell align="right">
            {get(result, 'goldLimited', 0)}
            </TableCell>
            <TableCell>Defense:</TableCell>
            <TableCell align="right">
            {get(result, 'defense', 0)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Material Produce:</TableCell>
            <TableCell align="right">
              {get(result, 'materialProduce', 0)}
            </TableCell>
            <TableCell>Material Storage:</TableCell>
            <TableCell align="right">
            {get(result, 'materialStorage', 0)}
            </TableCell>
            <TableCell>Material Limited:</TableCell>
            <TableCell align="right">
            {get(result, 'materialLimited', 0)}
            </TableCell>
            <TableCell>Population:</TableCell>
            <TableCell align="right">
            {get(result, 'population', 0)}
            </TableCell>
          </TableRow>
        </TableContainer>
      </Container>
      <Container>
        <TableContainer component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell>Block Number</TableCell>
              <TableCell align="right">Tier Number</TableCell>
              <TableCell align="right">Level Number</TableCell>
              <TableCell align="right">Block Type</TableCell>
            </TableRow>
          </TableHead>
          {block.map((item, index) => (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>Block {index + 1}</TableCell>
              <TableCell align="right">
                <TextField
                  type="number"
                  value={get(item, "tier", 1)}
                  onChange={(e) =>
                    changeBlockValue(`[${index}].tier`, e.target.value)
                  }
                  variant="standard"
                />
              </TableCell>
              <TableCell align="right">
                <TextField
                  type="number"
                  value={get(item, "level", 1)}
                  onChange={(e) =>
                    changeBlockValue(`[${index}].level`, e.target.value)
                  }
                  variant="standard"
                />
              </TableCell>
              <RadioGroup
                row
                aria-labelledby={`block${index + 1}`}
                name={`block${index + 1}`}
                value={get(item, "type", "apartment")}
                onChange={(e) =>
                  changeBlockValue(`[${index}].type`, e.target.value)
                }
              >
                <FormControlLabel
                  value="apartment"
                  control={<Radio />}
                  label="Apartment"
                />
                <FormControlLabel
                  value="mining"
                  control={<Radio />}
                  label="Mining"
                />
                <FormControlLabel
                  value="factory"
                  control={<Radio />}
                  label="Factory"
                />
                <FormControlLabel
                  value="gold storage"
                  control={<Radio />}
                  label="Gold Storage"
                />
                <FormControlLabel
                  value="material storage"
                  control={<Radio />}
                  label="Material Storage"
                />
              </RadioGroup>
            </TableRow>
          ))}
        </TableContainer>
      </Container>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Base Land</TableCell>
                <TableCell align="right">Tier 1</TableCell>
                <TableCell align="right">Tier 2</TableCell>
                <TableCell align="right">Tier 3</TableCell>
                <TableCell align="right">Tier 4</TableCell>
                <TableCell align="right">Tier 5</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Gold Storage Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldStorage.tier1", 100)}
                    onChange={(e) =>
                      changeValue("goldStorage.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldStorage.tier2", 200)}
                    onChange={(e) =>
                      changeValue("goldStorage.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldStorage.tier3", 300)}
                    onChange={(e) =>
                      changeValue("goldStorage.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldStorage.tier4", 400)}
                    onChange={(e) =>
                      changeValue("goldStorage.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldStorage.tier5", 500)}
                    onChange={(e) =>
                      changeValue("goldStorage.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Produce Gold Rate Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldRate.tier1", 1.01)}
                    onChange={(e) =>
                      changeValue("goldRate.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldRate.tier2", 1.02)}
                    onChange={(e) =>
                      changeValue("goldRate.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldRate.tier3", 1.03)}
                    onChange={(e) =>
                      changeValue("goldRate.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldRate.tier4", 1.04)}
                    onChange={(e) =>
                      changeValue("goldRate.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "goldRate.tier5", 1.05)}
                    onChange={(e) =>
                      changeValue("goldRate.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Building Material Storage Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialStorage.tier1", 100)}
                    onChange={(e) =>
                      changeValue("materialStorage.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialStorage.tier2", 200)}
                    onChange={(e) =>
                      changeValue("materialStorage.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialStorage.tier3", 300)}
                    onChange={(e) =>
                      changeValue("materialStorage.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialStorage.tier4", 400)}
                    onChange={(e) =>
                      changeValue("materialStorage.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialStorage.tier5", 500)}
                    onChange={(e) =>
                      changeValue("materialStorage.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Produce Building Material Rate Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialRate.tier1", 1.01)}
                    onChange={(e) =>
                      changeValue("materialRate.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialRate.tier2", 1.02)}
                    onChange={(e) =>
                      changeValue("materialRate.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialRate.tier3", 1.03)}
                    onChange={(e) =>
                      changeValue("materialRate.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialRate.tier4", 1.04)}
                    onChange={(e) =>
                      changeValue("materialRate.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "materialRate.tier5", 1.05)}
                    onChange={(e) =>
                      changeValue("materialRate.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Land Defense Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "denfense.tier1", 100)}
                    onChange={(e) =>
                      changeValue("denfense.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "denfense.tier2", 200)}
                    onChange={(e) =>
                      changeValue("denfense.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "denfense.tier3", 300)}
                    onChange={(e) =>
                      changeValue("denfense.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "denfense.tier4", 400)}
                    onChange={(e) =>
                      changeValue("denfense.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "denfense.tier5", 500)}
                    onChange={(e) =>
                      changeValue("denfense.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>Land Population Base Value</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "offense.tier1", 100)}
                    onChange={(e) =>
                      changeValue("offense.tier1", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "offense.tier2", 200)}
                    onChange={(e) =>
                      changeValue("offense.tier2", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "offense.tier3", 300)}
                    onChange={(e) =>
                      changeValue("offense.tier3", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "offense.tier4", 400)}
                    onChange={(e) =>
                      changeValue("offense.tier4", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(landValue, "offense.tier5", 500)}
                    onChange={(e) =>
                      changeValue("offense.tier5", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Container>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Room Buff</TableCell>
                <TableCell align="right">Multipler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>1 Different Room</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "room1", 1.01)}
                    onChange={(e) => changeRoomValue("room1", e.target.value)}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>2 Different Room</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "room2", 1.02)}
                    onChange={(e) => changeRoomValue("room2", e.target.value)}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>3 Different Room</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "room3", 1.03)}
                    onChange={(e) => changeRoomValue("room3", e.target.value)}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>4 Different Room</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "room4", 1.04)}
                    onChange={(e) => changeRoomValue("room4", e.target.value)}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>5 Different Room</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "room5", 1.05)}
                    onChange={(e) => changeRoomValue("room5", e.target.value)}
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>5 Different Room Extra Buff</TableCell>
                <TableCell align="right">
                  <TextField
                    type="number"
                    value={get(roomValue, "roomExtra", 1.05)}
                    onChange={(e) =>
                      changeRoomValue("roomExtra", e.target.value)
                    }
                    variant="standard"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Building
        typeName="Factory Level (Base Value)"
        keyName="factoryMaterialProduce"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
      <Building
        typeName="Building Material Storage Level (Base Value)"
        keyName="buildingMaterialStorage"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
      <Building
        typeName="Apartment Level - Population (Base Value)"
        keyName="apartmentPopulation"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
      <Building
        typeName="Apartment Level - Defense (Base Value)"
        keyName="apartmentDefense"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
      <Building
        typeName="Gold Mine Level (Base Value)"
        keyName="goldMine"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
      <Building
        typeName="Gold Mine Storage Level (Base Value)"
        keyName="goldStorage"
        defaultValue={defaultValue}
        updateValue={buildingValue}
        changeValue={changeBuildingValue}
      />
    </div>
  );
}

export default App;
